<template>
  <div class="py-4 container-fluid">
    <div class="row mb-4">
      <div class="col-lg-12 position-relative z-index-2">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-sm-6">
            <mini-statistics-card v-if="dashboard"
              :title="{ text: 'Transaction Count', value: dashboard.data.summary.count}"
              detail="<span class='text-success text-sm font-weight-bolder'>+0%</span> Just updated"
              :icon="{
                name: 'weekend',
                color: 'text-white',
                background: 'dark',
              }"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4">
            <mini-statistics-card v-if="dashboard"
              :title="{ text: 'Omset', value: dashboard.data.summary.grand_total }"
              detail="<span class='text-success text-sm font-weight-bolder'>+0%</span> Just updated"
              :icon="{
                name: 'leaderboard',
                color: 'text-white',
                background: 'primary',
              }"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4">
            <mini-statistics-card v-if="dashboard"
              :title="{ text: 'Revenue', value: dashboard.data.summary.admin_fee}"
              detail="<span class='text-success text-sm font-weight-bolder'>+0%</span> Exclude admin bank"
              :icon="{
                name: 'payments',
                color: 'text-white',
                background: 'warning',
              }"
            />
          </div>
          
          <div class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4">
            <mini-statistics-card v-if="dashboard"
              :title="{ text: 'Tax', value: dashboard.data.summary.tax }"
              detail="<span class='text-success text-sm font-weight-bolder'>+0%</span> Just updated"
              :icon="{
                name: 'receipt_long',
                color: 'text-white',
                background: 'success',
              }"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4">
            <mini-statistics-card v-if="dashboard"
              :title="{ text: 'Users', value: dashboard.data.customer[0].count }"
              detail="<span class='text-success text-sm font-weight-bolder'>+0%</span> Just updated"
              :icon="{
                name: 'people',
                color: 'text-white',
                background: 'info',
              }"
            />
          </div>
          
        </div>
        <div class="row mt-4">
          <div class="col-lg-4 col-md-6 mt-4">
            <chart-holder-card
              title="Event Reach"
              subtitle="Transaction Per Event"
              update="campaign sent 2 days ago"
            >
              <reports-bar-chart v-if="eventChart"
                :chart="{
                  labels: eventChart.label,
                  datasets: {
                    label: 'Sales',
                    data: eventChart.value,
                  },
                }"
              />
            </chart-holder-card>
          </div>
          <div class="col-lg-4 col-md-6 mt-4">
            <chart-holder-card
              title="Monthly Sales"
              subtitle="(<span class='font-weight-bolder'>+15%</span>) increase in today sales."
              update="updated 4 min ago"
              color="success"
            >
              <reports-line-chart v-if="salesChart"
                :chart="{
                  labels: salesChart.label,
                  datasets: {
                    label: 'Sales',
                    data: salesChart.value,
                  },
                }"
              />
            </chart-holder-card>
          </div>
          
          <div class="col-lg-4 mt-4">
            <chart-holder-card
              title="Transaction Chart"
              subtitle="Last Campaign Performance"
              update="just updated"
              color="dark"
            >
              <reports-doughnut-chart v-if="transactionChart"
                id="tasks-chart"
                :chart="{
                  labels: transactionChart.label,
                  datasets: {
                    label: 'Mobile apps',
                    data: transactionChart.value,
                  },
                }"
              />
            </chart-holder-card>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-8 col-md-6 mb-md-0 mb-4">
        <project-card v-if="transaction"
          title="Detail Event Reach"
          description="<i class='fa fa-check text-info' aria-hidden='true'></i> <span class='font-weight-bold ms-1'>Event</span> done"
          :headers="['Event', 'Status','Transaction', 'Omset', 'Revenue', 'Tax']"
          :projects="transaction"
        />
      </div>
      <div class="col-lg-4 col-md-6">
        <timeline-list
          class="h-100"
          title="New feature"
          description="<i class=' text-success' aria-hidden='true'></i>
        <span class='font-weight-bold'>Something big</span> will be launch soon"
        >
        <img src="../assets/girl-doing-yoga-light.png"  class="col-lg-12 col-md-12" alt="">
          
        </timeline-list>
      </div>
    </div>
  </div>
</template>
<script>
import ChartHolderCard from "./components/ChartHolderCard.vue";
import ReportsBarChart from "@/components/Charts/ReportsBarChart.vue";
import ReportsDoughnutChart from "@/components/Charts/ReportsDoughnutChart.vue";
import ReportsLineChart from "@/components/Charts/ReportsLineChart.vue";
import MiniStatisticsCard from "./components/MiniStatisticsCard.vue";
import ProjectCard from "./components/ProjectCard.vue";
import TimelineList from "@/components/Cards/TimelineList.vue";
import logoXD from "@/assets/img/small-logos/logo-xd.svg";
import logoAtlassian from "@/assets/img/small-logos/logo-atlassian.svg";
import logoSlack from "@/assets/img/small-logos/logo-slack.svg";
import logoSpotify from "@/assets/img/small-logos/logo-spotify.svg";
import logoJira from "@/assets/img/small-logos/logo-jira.svg";
import logoInvision from "@/assets/img/small-logos/logo-invision.svg";
import team1 from "@/assets/img/team-1.jpg";
import team2 from "@/assets/img/team-2.jpg";
import team3 from "@/assets/img/team-3.jpg";
import team4 from "@/assets/img/team-4.jpg";
var moment = require('moment');
export default {
  name: "dashboard-default",
  created() {
    const headers = { 
      "Content-Type": "application/json" ,
      "Authorization": "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJyb2xlIjoiYWRtaW4iLCJuYW1lIjoiQWRtaW4iLCJwaG9uZV9udW1iZXIiOiIwODEyMzY5ODc3NjMiLCJhZGRyZXNzIjoiSmFsYW4gS2VuYW5nYW4iLCJlbWFpbCI6ImFkbWluQGdtYWlsLmNvbSIsIm5payI6IjEyMzQ1Njc4OTAxMjM0NTYiLCJzZXgiOiJmIn0.AJUNrEG57ZRMBaY9w01gBU4RLdBAmYke6foRwFCsp0w"
    };
    fetch("https://api.tunaipay.com/api/v1/admin/v2/dashboard", { headers })
      .then(response => response.json())
      .then(data => {
        data.data.summary.grand_total = formatCurrency(data.data.summary.grand_total)
        data.data.summary.admin_fee = formatCurrency(data.data.summary.admin_fee)
        data.data.summary.tax = formatCurrency(data.data.summary.tax)

        let label = []
        let value = []
        let transaction = []
        for (let index = 0; index < 5; index++) {
          const element = data.data.transaction[index];
          label.push(element.name)
          value.push(parseInt(element.count))
          // transaction.push({
          //   logo: logoSlack,
          //   title: element.name,
          //   transaction: element.count,
          //   revenue: formatCurrency(element.grand_total),
          //   admin: formatCurrency(element.admin_fee),
          //   tax: formatCurrency(element.tax)
          // })
          
        }

        for (let index = 0; index < data.data.transaction.length; index++) {
          const element = data.data.transaction[index];
          
          transaction.push({
            logo: logoSlack,
            title: element.name,
            status: element.event_ended_at < moment().format('YYYY-MM-DD') ? 'Event Telah Berakhir' : 'Event Berjalan',
            transaction: element.count,
            revenue: formatCurrency(element.grand_total),
            admin: formatCurrency(element.admin_fee),
            tax: formatCurrency(element.tax)
          })
          
        }
        this.eventChart = {
          label: label,
          value: value
        }

        this.transaction = transaction
        this.dashboard = data

        let labelSales = []
        let valueSales = []
        for (let index = 0; index < data.data.sales.length; index++) {
          const element = data.data.sales[index];
          labelSales.push(moment(element.date_trunc).format("MMMM"))
          valueSales.push(parseInt(element.count))
        }
        this.salesChart = {
          label: labelSales,
          value: valueSales
        }

        let labelStatus = []
        let valueStatus = []
        for (let index = 0; index < data.data.status.length; index++) {
          const element = data.data.status[index];
          labelStatus.push(element.payment_status)
          valueStatus.push(parseInt(element.count))
        }
        this.transactionChart = {
          label: labelStatus,
          value: valueStatus
        }
      
  });
  },
  data() {
    return {
      logoXD,
      team1,
      team2,
      team3,
      team4,
      logoAtlassian,
      logoSlack,
      logoSpotify,
      logoJira,
      logoInvision,
      dashboard: null,
      eventChart: null,
      salesChart: null,
      transactionChart: null,
      transaction: null
    };
  },
  
  components: {
    ChartHolderCard,
    ReportsDoughnutChart,
    ReportsBarChart,
    ReportsLineChart,
    MiniStatisticsCard,
    ProjectCard,
    TimelineList,
  },
};
function formatCurrency(angka){
  angka = Math.floor(angka)
  var number_string = angka ? angka.toString() : "0"
  number_string = number_string.replace('.', ',')
  var split = number_string.split(",")
  var sisa = split[0].length % 3
  var rupiah = split[0].substr(0, sisa)
  var ribuan = split[0].substr(sisa).match(/\d{3}/gi)

    if (ribuan) {
      var separator = sisa ? "." : "";
      rupiah += separator + ribuan.join(".");
    }

    rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah;
    return  "Rp."+" "+ rupiah;

}
</script>
