<template>
  <navbar btnBackground="bg-gradient-success" />
  <div
    class="page-header align-items-start min-vh-100"
    style="
      background-image: url('https://images.unsplash.com/photo-1497294815431-9365093b7331?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1950&q=80');
    "
  >
    <span class="mask bg-gradient-dark opacity-6"></span>
    <div class="container my-auto">
      <div class="row">
        <div class="col-lg-4 col-md-8 col-12 mx-auto">
          <div class="card z-index-0 fadeIn3 fadeInBottom">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="bg-gradient-success shadow-success border-radius-lg py-3 pe-1"
              >
                <h4 class="text-white font-weight-bolder text-center mt-2 mb-0">
                  Sign in
                </h4>
                <div class="row mt-3">
                  <div class="col-2 text-center ms-auto">
                    <a class="btn btn-link px-3" href="javascript:;">
                      <i class="fab fa-facebook text-white text-lg"></i>
                    </a>
                  </div>
                  <div class="col-2 text-center px-1">
                    <a class="btn btn-link px-3" href="javascript:;">
                      <i class="fab fa-github text-white text-lg"></i>
                    </a>
                  </div>
                  <div class="col-2 text-center me-auto">
                    <a class="btn btn-link px-3" href="javascript:;">
                      <i class="fab fa-google text-white text-lg"></i>
                    </a>
                  </div>
                </div>
                
              </div>
            </div>
            <div class="card-body">
              <form role="form" class="text-start mt-3" @submit.prevent="submit">
                <div class="mb-3">
                  <div
                    class="input-group input-group-outline null"
                  >
                  <!-- <label :class="variant === 'static' ? '' : 'form-label'">Email</label> -->
                  <input type="text"
                  id="email"
                  placeholder="Email"
                  class="form-control"
                  v-model="form.email">
                  </div>
                  
                </div>
                <div class="mb-3">
                  <div
                    class="input-group input-group-outline null"
                  >
                  <!-- <label :class="variant === 'static' ? '' : 'form-label'">Password</label> -->
                  <input type="password"
                  id="password"
                  class="form-control"
                  placeholder="Password"
                  v-model="form.password">
                  </div>
                  
                </div>
                
                <material-switch id="rememberMe" name="rememberMe"
                  >Remember me</material-switch
                >
                <div class="text-center">
                  <p v-if="showError" class="text-danger">Failed Login </p>
                  
                  <material-button
                    
                    class="my-4 mb-2"
                    variant="gradient"
                    color="success"
                    fullWidth
                    >Sign in</material-button
                  >
                </div>
                
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="footer position-absolute bottom-2 py-2 w-100">
      <div class="container">
        <div class="row align-items-center justify-content-lg-between">
          <div class="col-12 col-md-6 my-auto">
            <div class="copyright text-center text-sm text-white text-lg-start">
              © {{ new Date().getFullYear() }}, made with
              <i class="fa fa-heart" aria-hidden="true"></i> by
              <a
                href="#"
                class="font-weight-bold text-white"
                target="_blank"
                >Single Fighter</a
              >
              for a better web.
            </div>
          </div>
          <div class="col-12 col-md-6">
            <ul
              class="nav nav-footer justify-content-center justify-content-lg-end"
            >
              <li class="nav-item">
                <a
                  href="#"
                  class="nav-link text-white"
                  target="_blank"
                  >Single Fighter Tim</a
                >
              </li>
              <li class="nav-item">
                <a
                  href="#"
                  class="nav-link text-white"
                  target="_blank"
                  >About Us</a
                >
              </li>
              <li class="nav-item">
                <a
                  href="#"
                  class="nav-link text-white"
                  target="_blank"
                  >Blog</a
                >
              </li>
              <li class="nav-item">
                <a
                  href="#"
                  class="nav-link pe-0 text-white"
                  target="_blank"
                  >License</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import Navbar from "@/components/PageLayout/Navbar.vue";
// import MaterialInput from "@/components/MaterialInput.vue";
import MaterialSwitch from "@/components/MaterialSwitch.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import { mapActions, mapMutations } from "vuex";

export default {
  name: "sign-in",
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      showError: false
    };
  },
  components: {
    Navbar,
    // MaterialInput,
    MaterialSwitch,
    MaterialButton,
  },
  beforeMount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    ...mapActions(["setLogin", "setUser"]),
    async submit() {
      console.log("HITTT",this.form.email)
      const User = new FormData();
      User.append("email", this.form.email);
      User.append("password", this.form.password);
      
      try {
        if(this.form.email == "tech@etupay.com" && this.form.password == "Invisible@2023!"){
          // this.$router.push("/dashboard");
          await this.setLogin();
          // await this.setUser(User);
          this.$router.push("/dashboard");
          this.showError = false
        }else{
          this.showError = true
        }
         
      } catch (error) {
        this.showError = true
      }
    },
    
  },
};
</script>
