<template>
  <div class="py-4 container-fluid">

    <div class="row">
      <div class="col-lg-12 col-md-12 text-center">
        <timeline-list
          class="h-100"
          title="New feature"
          description="<i class=' text-success' aria-hidden='true'></i>
        <span class='font-weight-bold'>Something big</span> will be launch soon"
        >
        <p class="text-center">Something Big Will Be Launch</p>
        <img src="../assets/girl-doing-yoga-light.png" height="600" alt="">
          
        </timeline-list>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: "not-found",
};

</script>
