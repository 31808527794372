



import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import Dashboard from "./dashboard";
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

const appInstance = createApp(App);
appInstance.use(store);
appInstance.use(router);
appInstance.use(Dashboard);
appInstance.use(ElementPlus)
appInstance.mount("#app");


