<template>
  <div class="py-4 container-fluid">
    <div class="row mb-4">
      <div class="col-lg-12 position-relative z-index-2">
        <div class="row">
          <el-form :inline="true" class="demo-form-inline">
           <el-form-item class="bg-transparent" :style="{width: '200px'}">
              <el-select
                filterable
                v-model="selectedEvent"
                size="medium"
                placeholder="Pilih Event"
              >
                <el-option label="All Events" value=""></el-option>
                <el-option
                  v-for="(item, k) in events"
                  :key="k"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
           
            <el-form-item>
              <el-button
                style="padding: 0 2em"
                :loading="btnLoading"
                @click="getDashboard()"
                type="primary"
                >Apply</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12 col-md-6 mb-md-0 mb-4">
        <project-card
          v-if="transaction"
          title="List Products"
          description="<i class='fa fa-check text-info' aria-hidden='true'></i> <span class='font-weight-bold ms-1'>List</span> Products"
          :headers="['Product Name', 'Event Name', 'Stock', 'Price', 'Status']"
          :projects="transaction"
        />
      </div>
      
    </div>
  </div>
</template>
<script>

import ProjectCard from "../components/ProjectCard.vue";
import logoXD from "@/assets/img/small-logos/logo-xd.svg";
import logoAtlassian from "@/assets/img/small-logos/logo-atlassian.svg";
import logoSlack from "@/assets/img/small-logos/logo-slack.svg";
import logoSpotify from "@/assets/img/small-logos/logo-spotify.svg";
import logoJira from "@/assets/img/small-logos/logo-jira.svg";
import logoInvision from "@/assets/img/small-logos/logo-invision.svg";
import team1 from "@/assets/img/team-1.jpg";
import team2 from "@/assets/img/team-2.jpg";
import team3 from "@/assets/img/team-3.jpg";
import team4 from "@/assets/img/team-4.jpg";
export default {
  name: "dashboard-default",
  data() {
    return {
      logoXD,
      team1,
      team2,
      team3,
      team4,
      logoAtlassian,
      logoSlack,
      logoSpotify,
      logoJira,
      logoInvision,
      transaction: null,
      selectedEvent: '',
      dateRange: [], // Variabel untuk menyimpan rentang tanggal yang dipilih
      pickerOptions: {
        shortcuts: [
          {
            text: 'Hari Ini',
            onClick(picker) {
              const today = new Date();
              picker.$emit('pick', [today, today]); // Atur rentang tanggal ke hari ini
            },
          },
          {
            text: 'Minggu Ini',
            onClick(picker) {
              const today = new Date();
              const endOfWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 6);
              picker.$emit('pick', [today, endOfWeek]); // Atur rentang tanggal ke minggu ini
            },
          },
        ],
      },
      events: [],
      options: [
        { name: "Cat", id: "cat" },
        { name: "Dog", id: "dog" },
        { name: "Elephant", id: "elephant" },
        { name: "Girafe", id: "girafe" },
        { name: "Snake", id: "snake" },
        { name: "Spider", id: "spider" },
        { name: "Unicorn", id: "unicorn" },
      ],
    };
  },
  created() {
    const headers = {
      "Content-Type": "application/json",
      Authorization:
        "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJyb2xlIjoiYWRtaW4iLCJuYW1lIjoiQWRtaW4iLCJwaG9uZV9udW1iZXIiOiIwODEyMzY5ODc3NjMiLCJhZGRyZXNzIjoiSmFsYW4gS2VuYW5nYW4iLCJlbWFpbCI6ImFkbWluQGdtYWlsLmNvbSIsIm5payI6IjEyMzQ1Njc4OTAxMjM0NTYiLCJzZXgiOiJmIn0.AJUNrEG57ZRMBaY9w01gBU4RLdBAmYke6foRwFCsp0w",
    };
    fetch(`${process.env.VUE_APP_API_URL}/product`, { headers, method: 'POST', body: null })
      .then((response) => response.json())
      .then((data) => {
       
        let transaction = [];
        
        for (let index = 0; index < data.data.length; index++) {
          const element = data.data[index];

          transaction.push({
            logo: logoSlack,
            title: element.name,
            transaction: element.event.name,
            revenue: element.stock,
            admin: element.normal_price,
            tax: element.is_active ? 'Active' : 'Inactive',
          });
        }
        

        this.transaction = transaction;
      });
    fetch("https://api.tunaipay.com/api/v1/event", { headers })
      .then((response) => response.json())
      .then((data) => {
        this.events = data.data;
      });
  },
  
  methods: {
    async getDashboard() {
      const headers = {
        "Content-Type": "application/json",
        Authorization:
          "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJyb2xlIjoiYWRtaW4iLCJuYW1lIjoiQWRtaW4iLCJwaG9uZV9udW1iZXIiOiIwODEyMzY5ODc3NjMiLCJhZGRyZXNzIjoiSmFsYW4gS2VuYW5nYW4iLCJlbWFpbCI6ImFkbWluQGdtYWlsLmNvbSIsIm5payI6IjEyMzQ1Njc4OTAxMjM0NTYiLCJzZXgiOiJmIn0.AJUNrEG57ZRMBaY9w01gBU4RLdBAmYke6foRwFCsp0w",
      };
      var data = null
      if(this.selectedEvent != '' && this.selectedEvent != 'all'){
        data = {
            eventId: this.selectedEvent
        }
      }
      

      fetch(`${process.env.VUE_APP_API_URL}/product`, { headers, method: 'POST', body: JSON.stringify(data) })
        .then((response) => response.json())
        .then((data) => {
          let transaction = [];
          for (let index = 0; index < data.data.length; index++) {
            const element = data.data[index];

            transaction.push({
                logo: logoSlack,
                title: element.name,
                transaction: element.event.name,
                revenue: element.stock,
                admin: element.normal_price,
                tax: element.is_active ? 'Active' : 'Inactive',
            });
          }
          this.transaction = transaction;
         
        });
    },
  },

  components: {
    
    ProjectCard
  },
};

</script>
